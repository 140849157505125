/*General CSS*/
.justify {
    text-align: justify;
}

.text-colour-white {
    color: white;
}

p {
    white-space: pre-line;
}

.prevBtn {
    background-color: grey !important;
}

.nextBtn {
    background-color: grey !important;
}

.owl-prev {
    background-color: grey !important;
}

.owl-next {
    background-color: grey !important;
}

.logo{
    margin-top: 0.5rem;
}

.logo-mob{
    width: 16rem;
}

.mob-icon{
    margin-top: 1.75rem;
    margin-left: 4rem;
    font-size: 2rem;
}

.top-section-limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 40px;
    max-height: 52px;
}

.feature-section-limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 40px;
    max-height: 52px;
}

.general-section-limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 43px;
    max-height: 52px;
}

.general-section-date{
    padding-bottom: 0.5rem;
}

.general-section-limited-text-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 40px;
    max-height: 42px;
}

.general-section-limited-image {
    width: 1920px;
    height: 12.5rem;
    background-position: center center;
    background-repeat: no-repeat;
}

/*Specific News*/
.Specific-section-limited-image {
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*Feature News*/
.feature-section-limited-image {
    width: 510px;
    height: 18rem;
    background-position: center center;
    background-repeat: no-repeat;
}

.feature-section-limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 73.5px;
    max-height: 73.5px;
}

/*Local Section*/
.local-section-limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 43px;
    max-height: 52px;
    font-size: 15px;
}

/*Local Section*/
.local-section-limited-image {
    width: 160px;
    height: 135rem;
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 0.5rem;
}

/*Side Bar Section*/
.side-section-limited-image {
    width: 100px;
    height: 75px;
    background-position: center center;
    background-repeat: no-repeat;
}

.side-section-limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 43px;
    max-height: 52px;
    font-size: 15px;
}

/* Hide On Mob View */
@media screen and (min-width: 481px) {
    .mob-only {
        display: none;
    }

    .feature-section-limited-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        height: 65px;
        max-height: 65px;
    }
}

@media(max-width:991px){
    .desk-only {
        display: none;
    }
}


/*Define main font on google font*/

.alignleft {
    float: left;
    margin-right: 20px;
}

.alignright {
    float: right;
    margin-left: 20px;
}


.aligncenter {
    display: block;
    margin: 0 auto 20px;
}

a {
    color: #3e425c;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

a:hover {
    color: #2095f3;
}

a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
}


body,
html {
    height: 100%;
}


h1,
h2,
h3,
h4,
h5, 
h6{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin: 0;
}

p{
    margin: 0;
}

.tuc{
    text-transform: uppercase;
}
.tct{
    text-transform: capitalize;
}

a, a:hover{
    text-decoration: none;
}
.font_light{
    font-weight: 300;
}
.font_bold{
    font-weight: 700
}
.font_regular{
    font-weight: 400;
}
.font_thin{
    font-weight: 100;
}
.b{
    font-weight: 700;
}
/*
ul{
    margin: 0;
    padding:0;
}
*
li{
    list-style: none;
}

body {
    font-size: 16px;
    line-height: 27px;
    color: #111111;
    font-family: 'Karla', sans-serif;
    font-weight: 500;
    overflow-x: hidden;
}


/* Remove Chrome Input Field's Unwanted Yellow Background Color */

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

/*----------------------
    ALIGN
-----------------------*/

.center,
.left,
.right {
    position: relative;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.dinline li, 
.dinline, 
.dinline a, 
.dinline > div{
    display: inline-block;
}

/*-----------------------
    FONTS
------------------------*/
.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.font12 {
    font-size: 12px;
}

.font14 {
    font-size: 14px;
}

.font16 {
    font-size: 16px;
}

.font18 {
    font-size: 18px;
}

.font20 {
    font-size: 20px;
}

.font22 {
    font-size: 22px;
}

.font24 {
    font-size: 24px;
}

.font26 {
    font-size: 26px;
}

.font28 {
    font-size: 28px;
}

.font32 {
    font-size: 32px;
}

.font30 {
    font-size: 30px;
}

.font36 {
    font-size: 36px;
}

.font38 {
    font-size: 38px;
}

.font48 {
    font-size: 48px;
}

.font42 {
    font-size: 42px;
}

.font48 {
    font-size: 48px;
}

.font50 {
    font-size: 50px;
}

.font52 {
    font-size: 52px;
}

.font60 {
    font-size: 60px;
}

.font80 {
    font-size: 80px;
}

.font120 {
    font-size: 120px;
}

.font140 {
    font-size: 140px;
}

/*------------------------------
    RESPONSIVE
-------------------------------*/
@media only screen and (min-width: 1200px) {
    .lg-center {
        text-align: center;
    }

    .lg-left {
        text-align: left;
    }

    .lg-right {
        text-align: right;
    }

    .lg-font12 {
        font-size: 12px;
    }

    .lg-font14 {
        font-size: 14px;
    }

    .lg-font16 {
        font-size: 16px;
    }

    .lg-font18 {
        font-size: 18px;
    }

    .lg-font20 {
        font-size: 20px;
    }

    .lg-font22 {
        font-size: 22px;
    }

    .lg-font24 {
        font-size: 24px;
    }

    .lg-font26 {
        font-size: 26px;
    }

    .lg-font28 {
        font-size: 28px;
    }

    .lg-font32 {
        font-size: 32px;
    }

    .lg-font30 {
        font-size: 30px;
    }

    .lg-font36 {
        font-size: 36px;
    }

    .lg-font38 {
        font-size: 38px;
    }

    .lg-font48 {
        font-size: 48px;
    }

    .lg-font42 {
        font-size: 42px;
    }

    .lg-font48 {
        font-size: 48px;
    }

    .lg-font50 {
        font-size: 50px;
    }

    .lg-font52 {
        font-size: 52px;
    }

    .lg-font60 {
        font-size: 60px;
    }

    .lg-font80 {
        font-size: 80px;
    }

    .lg-font120 {
        font-size: 120px;
    }

    .lg-font140 {
        font-size: 140px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .md-center {
        text-align: center;
    }

    .md-left {
        text-align: left;
    }

    .md-right {
        text-align: right;
    }

    .md-font12 {
        font-size: 12px;
    }

    .md-font14 {
        font-size: 14px;
    }

    .md-font16 {
        font-size: 16px;
    }

    .md-font18 {
        font-size: 18px;
    }

    .md-font20 {
        font-size: 20px;
    }

    .md-font22 {
        font-size: 22px;
    }

    .md-font24 {
        font-size: 24px;
    }

    .md-font26 {
        font-size: 26px;
    }

    .md-font28 {
        font-size: 28px;
    }

    .md-font32 {
        font-size: 32px;
    }

    .md-font30 {
        font-size: 30px;
    }

    .md-font36 {
        font-size: 36px;
    }

    .md-font38 {
        font-size: 38px;
    }

    .md-font48 {
        font-size: 48px;
    }

    .md-font42 {
        font-size: 42px;
    }

    .md-font48 {
        font-size: 48px;
    }

    .md-font50 {
        font-size: 50px;
    }

    .md-font52 {
        font-size: 52px;
    }

    .md-font60 {
        font-size: 60px;
    }

    .md-font80 {
        font-size: 80px;
    }

    .md-font120 {
        font-size: 120px;
    }

    .md-font140 {
        font-size: 140px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .sm-center {
        text-align: center;
    }

    .sm-left {
        text-align: left;
    }

    .sm-right {
        text-align: right;
    }

    .sm-font12 {
        font-size: 12px;
    }

    .sm-font14 {
        font-size: 14px;
    }

    .sm-font16 {
        font-size: 16px;
    }

    .sm-font18 {
        font-size: 18px;
    }

    .sm-font20 {
        font-size: 20px;
    }

    .sm-font22 {
        font-size: 22px;
    }

    .sm-font24 {
        font-size: 24px;
    }

    .sm-font26 {
        font-size: 26px;
    }

    .sm-font28 {
        font-size: 28px;
    }

    .sm-font32 {
        font-size: 32px;
    }

    .sm-font30 {
        font-size: 30px;
    }

    .sm-font36 {
        font-size: 36px;
    }

    .sm-font38 {
        font-size: 38px;
    }

    .sm-font48 {
        font-size: 48px;
    }

    .sm-font42 {
        font-size: 42px;
    }

    .sm-font48 {
        font-size: 48px;
    }

    .sm-font50 {
        font-size: 50px;
    }

    .sm-font52 {
        font-size: 52px;
    }

    .sm-font60 {
        font-size: 60px;
    }

    .sm-font80 {
        font-size: 80px;
    }

    .sm-font120 {
        font-size: 120px;
    }

    .sm-font140 {
        font-size: 140px;
    }
}

@media only screen and (max-width: 767px) {
    .xs-center {
        text-align: center;
    }

    .xs-left {
        text-align: left;
    }

    .xs-right {
        text-align: right;
    }

    .xs-font12 {
        font-size: 12px;
    }

    .xs-font14 {
        font-size: 14px;
    }

    .xs-font16 {
        font-size: 16px;
    }

    .xs-font18 {
        font-size: 18px;
    }

    .xs-font20 {
        font-size: 20px;
    }

    .xs-font22 {
        font-size: 22px;
    }

    .xs-font24 {
        font-size: 24px;
    }

    .xs-font26 {
        font-size: 26px;
    }

    .xs-font28 {
        font-size: 28px;
    }

    .xs-font32 {
        font-size: 32px;
    }

    .xs-font30 {
        font-size: 30px;
    }

    .xs-font36 {
        font-size: 36px;
    }

    .xs-font38 {
        font-size: 38px;
    }

    .xs-font48 {
        font-size: 48px;
    }

    .xs-font42 {
        font-size: 42px;
    }

    .xs-font48 {
        font-size: 48px;
    }

    .xs-font50 {
        font-size: 50px;
    }

    .xs-font52 {
        font-size: 52px;
    }

    .xs-font60 {
        font-size: 60px;
    }

    .xs-font80 {
        font-size: 80px;
    }

    .xs-font120 {
        font-size: 120px;
    }

    .xs-font140 {
        font-size: 140px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .xsl-center {
        text-align: center;
    }

    .xsl-left {
        text-align: left;
    }

    .xsl-right {
        text-align: right;
    }

    .xsl-font12 {
        font-size: 12px;
    }

    .xsl-font14 {
        font-size: 14px;
    }

    .xsl-font16 {
        font-size: 16px;
    }

    .xsl-font18 {
        font-size: 18px;
    }

    .xsl-font20 {
        font-size: 20px;
    }

    .xsl-font22 {
        font-size: 22px;
    }

    .xsl-font24 {
        font-size: 24px;
    }

    .xsl-font26 {
        font-size: 26px;
    }

    .xsl-font28 {
        font-size: 28px;
    }

    .xsl-font32 {
        font-size: 32px;
    }

    .xsl-font30 {
        font-size: 30px;
    }

    .xsl-font36 {
        font-size: 36px;
    }

    .xsl-font38 {
        font-size: 38px;
    }

    .xsl-font48 {
        font-size: 48px;
    }

    .xsl-font42 {
        font-size: 42px;
    }

    .xsl-font48 {
        font-size: 48px;
    }

    .xsl-font50 {
        font-size: 50px;
    }

    .xsl-font52 {
        font-size: 52px;
    }

    .xsl-font60 {
        font-size: 60px;
    }

    .xsl-font80 {
        font-size: 80px;
    }

    .xsl-font120 {
        font-size: 120px;
    }

    .xsl-font140 {
        font-size: 140px;
    }
}

/*--------------------
    WIDTH
---------------------*/
.width50 {
    width: 50px;
}

.width60 {
    width: 60px;
}

.width70 {
    width: 70px;
}

.width80 {
    width: 80px;
}

.width90 {
    width: 90px;
}

.width100 {
    width: 100px;
}

.width110 {
    width: 110px;
}

.width120 {
    width: 120px;
}

.width50p {
    width: 50%;
}

.width70p {
    width: 70%;
}

.width80p {
    width: 80%;
}

.width90p {
    width: 90%;
}

.width100p,
.width100p img {
    width: 100%;
}

/*-----------------------
    DISPLAY
------------------------*/
.display-table {
    display: table;
}

.display-table-cell {
    display: table-cell;
}

.vertical-align-middle {
    vertical-align: middle;
}


.last-p-mb0 p:last-of-type {
    margin-bottom: 0;
}

.first-h4-mb0 h4:first-of-type {
    margin-bottom: 0;
}

.font300 {
    font-weight: 300;
}

.font400 {
    font-weight: 400;
}

.font500 {
    font-weight: 500;
}

.font600 {
    font-weight: 600;
}

.font700 {
    font-weight: 700;
}

.font800 {
    font-weight: 800;
}

/*--------------------
    CONTENT POSITION
---------------------*/
.fix {
    overflow: hidden;
}

.relative {
    position: relative;
    overflow: hidden;
}

.absulute {
    position: absolute;
}

.v-center {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    z-index: 9;
}

.h-center {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.h-center-reverse {
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
}

.content-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.flex-v-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.display-block {
    display: block;
}

.social-bookmark li {
    display: inline;
}

.social-bookmark li a {
    display: inline-block;
    margin: 0 2px;
    text-align: center;
}

.inline li {
    display: inline;
}

.inline-block li {
    display: inline-block;
}

.inline-block {
    display: inline-block;
}

@media (min-width: 768px) and (max-width: 991px) {
    .sm-width100p {
        width: 100%;
    }

    .flex-v-center {
        display: inherit;
    }
}

@media only screen and (max-width: 767px) {
    .xs-width100p {
        width: 100%;
    }

    .flex-v-center {
        -webkit-box-align: inherit;
        -ms-flex-align: inherit;
        align-items: inherit;
        display: inherit;
    }
}

/*--------------------------
    MARGIN CSS
----------------------------*/
.mt100 {
    margin-top: 100px;
}

.mt90 {
    margin-top: 90px;
}

.mt80 {
    margin-top: 80px;
}

.mt70 {
    margin-top: 70px;
}

.mt60 {
    margin-top: 60px;
}

.mt50 {
    margin-top: 50px;
}

.mt40 {
    margin-top: 40px;
}

.mt30 {
    margin-top: 30px;
}

.mt20 {
    margin-top: 20px;
}

.mt10 {
    margin-top: 10px;
}

.no-margin {
    margin: 0;
}

.mb100 {
    margin-bottom: 100px;
}
.mb150 {
    margin-bottom: 150px;
}

.mb90 {
    margin-bottom: 90px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb70 {
    margin-bottom: 70px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb10 {
    margin-bottom: 10px;
}


.mb15 {
    margin-bottom: 15px;
}

.mb0 {
    margin-bottom: 0;
}

@media only screen and (min-width: 1200px) {

    /*----------------------------------
        MEDIUM LAYOUT: 1280px
    -----------------------------------*/
    .lg-mb0 {
        margin-bottom: 0;
    }

    .lg-mb10 {
        margin-bottom: 10px;
    }

    .lg-mb20 {
        margin-bottom: 20px;
    }

    .lg-mb30 {
        margin-bottom: 30px;
    }

    .lg-mb40 {
        margin-bottom: 40px;
    }

    .lg-mb50 {
        margin-bottom: 50px;
    }

    .lg-mb60 {
        margin-bottom: 60px;
    }

    .lg-mb70 {
        margin-bottom: 70px;
    }

    .lg-mb80 {
        margin-bottom: 80px;
    }

    .lg-mb100 {
        margin-bottom: 100px;
    }

    .lg-mt0 {
        margin-top: 0;
    }

    .lg-mt10 {
        margin-top: 10px;
    }

    .lg-mt20 {
        margin-top: 20px;
    }

    .lg-mt30 {
        margin-top: 30px;
    }

    .lg-mt40 {
        margin-top: 40px;
    }

    .lg-mt50 {
        margin-top: 50px;
    }

    .lg-mt60 {
        margin-top: 60px;
    }

    .lg-mt70 {
        margin-top: 70px;
    }

    .lg-mt80 {
        margin-top: 80px;
    }

    .lg-mt100 {
        margin-top: 100px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

    /*----------------------------------
        MEDIUM LAYOUT: 1280px
    -----------------------------------*/
    .md-mb0 {
        margin-bottom: 0;
    }

    .md-mb10 {
        margin-bottom: 10px;
    }

    .md-mb20 {
        margin-bottom: 20px;
    }

    .md-mb30 {
        margin-bottom: 30px;
    }

    .md-mb40 {
        margin-bottom: 40px;
    }

    .md-mb50 {
        margin-bottom: 50px;
    }

    .md-mb60 {
        margin-bottom: 60px;
    }

    .md-mb70 {
        margin-bottom: 70px;
    }

    .md-mb80 {
        margin-bottom: 80px;
    }

    .md-mb100 {
        margin-bottom: 100px;
    }

    .md-mt0 {
        margin-top: 0!important;
    }

    .md-mt10 {
        margin-top: 10px;
    }

    .md-mt20 {
        margin-top: 20px;
    }

    .md-mt30 {
        margin-top: 30px;
    }

    .md-mt40 {
        margin-top: 40px;
    }

    .md-mt50 {
        margin-top: 50px;
    }

    .md-mt60 {
        margin-top: 60px;
    }

    .md-mt70 {
        margin-top: 70px;
    }

    .md-mt80 {
        margin-top: 80px;
    }

    .md-mt100 {
        margin-top: 100px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    /*----------------------------------
        TABLET LAYOUT: 768px
    -----------------------------------*/
    .sm-mb0 {
        margin-bottom: 0;
    }

    .sm-mb10 {
        margin-bottom: 10px;
    }

    .sm-mb20 {
        margin-bottom: 20px;
    }

    .sm-mb30 {
        margin-bottom: 30px;
    }

    .sm-mb40 {
        margin-bottom: 40px;
    }

    .sm-mb50 {
        margin-bottom: 50px;
    }

    .sm-mb60 {
        margin-bottom: 60px;
    }

    .sm-mb70 {
        margin-bottom: 70px;
    }

    .sm-mb80 {
        margin-bottom: 80px;
    }

    .sm-mb100 {
        margin-bottom: 100px;
    }

    .sm-mt0 {
        margin-top: 0!important;
    }

    .sm-mt10 {
        margin-top: 10px;
    }

    .sm-mt20 {
        margin-top: 20px;
    }

    .sm-mt30 {
        margin-top: 30px;
    }

    .sm-mt40 {
        margin-top: 40px;
    }

    .sm-mt50 {
        margin-top: 50px;
    }

    .sm-mt60 {
        margin-top: 60px;
    }

    .sm-mt70 {
        margin-top: 70px;
    }

    .sm-mt80 {
        margin-top: 80px;
    }

    .sm-mt100 {
        margin-top: 100px;
    }
}

@media only screen and (max-width: 767px) {

    /*----------------------------------
        MOBILE LAYOUT: 320px
    -----------------------------------*/
    .xs-mb0 {
        margin-bottom: 0;
    }

    .xs-mb10 {
        margin-bottom: 10px;
    }

    .xs-mb20 {
        margin-bottom: 20px;
    }

    .xs-mb30 {
        margin-bottom: 30px;
    }

    .xs-mb40 {
        margin-bottom: 40px;
    }

    .xs-mb50 {
        margin-bottom: 50px;
    }

    .xs-mb60 {
        margin-bottom: 60px;
    }

    .xs-mb70 {
        margin-bottom: 70px;
    }

    .xs-mb80 {
        margin-bottom: 80px;
    }

    .xs-mb90 {
        margin-bottom: 90px;
    }

    .xs-mb100 {
        margin-bottom: 100px;
    }

    .xs-mt0 {
        margin-top: 0;
    }

    .xs-mt10 {
        margin-top: 10px;
    }

    .xs-mt20 {
        margin-top: 20px;
    }

    .xs-mt30 {
        margin-top: 30px;
    }

    .xs-mt40 {
        margin-top: 40px;
    }

    .xs-mt50 {
        margin-top: 50px;
    }

    .xs-mt60 {
        margin-top: 60px;
    }

    .xs-mt70 {
        margin-top: 70px;
    }

    .xs-mt80 {
        margin-top: 80px;
    }

    .xs-mt100 {
        margin-top: 100px;
    }
}

/* ----------------------------
    PADDING CSS
------------------------------ */
.section-padding {
    padding: 100px 0 70px 0;
}

.section-padding2{
    padding: 100px 0;
}


.padding-top {
    padding-top: 100px;
}

.padding-bottom {
    padding-bottom: 100px;
}

.padding5050{
    padding: 50px 0;
}

.padding2020{
    padding: 20px 0;
}
.padding6030{
    padding: 60px 0 30px;
}

.padding15{
    padding: 15px;
}

/*-----------------*/
.padding-top-10 {
    padding-top: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-30 {
    padding-top: 30px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-70 {
    padding-top: 70px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-90 {
    padding-top: 90px;
}

.padding-top-100 {
    padding-top: 100px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-70 {
    padding-bottom: 70px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-90 {
    padding-bottom: 90px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}

/*--------------------
    PADDING
---------------------*/
.padding100 {
    padding: 100px;
}

.padding90 {
    padding: 90px;
}

.padding80 {
    padding: 80px;
}

.padding70 {
    padding: 70px;
}

.padding60 {
    padding: 60px;
}

.padding50 {
    padding: 50px;
}

.padding40 {
    padding: 40px;
}

.padding30 {
    padding: 30px;
}

.padding20 {
    padding: 20px !important;
}

.padding10 {
    padding: 10px;
}

.no-padding {
    padding: 0;
}

/*--------------------------------------
        RESPONSIVE
----------------------------------------*/
@media only screen and (min-width: 1200px) {
    .lg-padding-top-10 {
        padding-top: 10px;
    }

    .lg-padding-top-20 {
        padding-top: 20px;
    }

    .lg-padding-top-30 {
        padding-top: 30px;
    }

    .lg-padding-top-40 {
        padding-top: 40px;
    }

    .lg-padding-top-50 {
        padding-top: 50px;
    }

    .lg-padding-top-60 {
        padding-top: 60px;
    }

    .lg-padding-top-70 {
        padding-top: 70px;
    }

    .lg-padding-top-80 {
        padding-top: 80px;
    }

    .lg-padding-top-90 {
        padding-top: 90px;
    }

    .lg-padding-top-100 {
        padding-top: 100px;
    }

    .lg-padding-bottom-10 {
        padding-bottom: 10px;
    }

    .lg-padding-bottom-20 {
        padding-bottom: 20px;
    }

    .lg-padding-bottom-30 {
        padding-bottom: 30px;
    }

    .lg-padding-bottom-40 {
        padding-bottom: 40px;
    }

    .lg-padding-bottom-50 {
        padding-bottom: 50px;
    }

    .lg-padding-bottom-60 {
        padding-bottom: 60px;
    }

    .lg-padding-bottom-70 {
        padding-bottom: 70px;
    }

    .lg-padding-bottom-80 {
        padding-bottom: 80px;
    }

    .lg-padding-bottom-90 {
        padding-bottom: 90px;
    }

    .lg-padding-bottom-100 {
        padding-bottom: 100px;
    }

    /*------------------*/
    .lg-padding100 {
        padding: 100px;
    }

    .lg-padding90 {
        padding: 90px;
    }

    .lg-padding80 {
        padding: 80px;
    }

    .lg-padding70 {
        padding: 70px;
    }

    .lg-padding60 {
        padding: 60px;
    }

    .lg-padding50 {
        padding: 50px;
    }

    .lg-padding40 {
        padding: 40px;
    }

    .lg-padding30 {
        padding: 30px;
    }

    .lg-padding20 {
        padding: 20px;
    }

    .lg-padding10 {
        padding: 10px;
    }

    .lg-padding0 {
        padding: 0px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .md-padding-top-10 {
        padding-top: 10px;
    }

    .md-padding-top-20 {
        padding-top: 20px;
    }

    .md-padding-top-30 {
        padding-top: 30px;
    }

    .md-padding-top-40 {
        padding-top: 40px;
    }

    .md-padding-top-50 {
        padding-top: 50px;
    }

    .md-padding-top-60 {
        padding-top: 60px;
    }

    .md-padding-top-70 {
        padding-top: 70px;
    }

    .md-padding-top-80 {
        padding-top: 80px;
    }

    .md-padding-top-90 {
        padding-top: 90px;
    }

    .md-padding-top-100 {
        padding-top: 100px;
    }

    .md-padding-bottom-10 {
        padding-bottom: 10px;
    }

    .md-padding-bottom-20 {
        padding-bottom: 20px;
    }

    .md-padding-bottom-30 {
        padding-bottom: 30px;
    }

    .md-padding-bottom-40 {
        padding-bottom: 40px;
    }

    .md-padding-bottom-50 {
        padding-bottom: 50px;
    }

    .md-padding-bottom-60 {
        padding-bottom: 60px;
    }

    .md-padding-bottom-70 {
        padding-bottom: 70px;
    }

    .md-padding-bottom-80 {
        padding-bottom: 80px;
    }

    .md-padding-bottom-90 {
        padding-bottom: 90px;
    }

    .md-padding-bottom-100 {
        padding-bottom: 100px;
    }

    /*------------------*/
    .md-padding100 {
        padding: 100px;
    }

    .md-padding90 {
        padding: 90px;
    }

    .md-padding80 {
        padding: 80px;
    }

    .md-padding70 {
        padding: 70px;
    }

    .md-padding60 {
        padding: 60px;
    }

    .md-padding50 {
        padding: 50px;
    }

    .md-padding40 {
        padding: 40px;
    }

    .md-padding30 {
        padding: 30px;
    }

    .md-padding20 {
        padding: 20px;
    }

    .md-padding10 {
        padding: 10px;
    }

    .md-padding0 {
        padding: 0px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .sm-padding-top-10 {
        padding-top: 10px;
    }

    .sm-padding-top-20 {
        padding-top: 20px;
    }

    .sm-padding-top-30 {
        padding-top: 30px;
    }

    .sm-padding-top-40 {
        padding-top: 40px;
    }

    .sm-padding-top-50 {
        padding-top: 50px;
    }

    .sm-padding-top-60 {
        padding-top: 60px;
    }

    .sm-padding-top-70 {
        padding-top: 70px;
    }

    .sm-padding-top-80 {
        padding-top: 80px;
    }

    .sm-padding-top-90 {
        padding-top: 90px;
    }

    .sm-padding-top-100 {
        padding-top: 100px;
    }

    .sm-padding-bottom-10 {
        padding-bottom: 10px;
    }

    .sm-padding-bottom-20 {
        padding-bottom: 20px;
    }

    .sm-padding-bottom-30 {
        padding-bottom: 30px;
    }

    .sm-padding-bottom-40 {
        padding-bottom: 40px;
    }

    .sm-padding-bottom-50 {
        padding-bottom: 50px;
    }

    .sm-padding-bottom-60 {
        padding-bottom: 60px;
    }

    .sm-padding-bottom-70 {
        padding-bottom: 70px;
    }

    .sm-padding-bottom-80 {
        padding-bottom: 80px;
    }

    .sm-padding-bottom-90 {
        padding-bottom: 90px;
    }

    .sm-padding-bottom-100 {
        padding-bottom: 100px;
    }

    /*------------------*/
    .sm-padding100 {
        padding: 100px;
    }

    .sm-padding90 {
        padding: 90px;
    }

    .sm-padding80 {
        padding: 80px;
    }

    .sm-padding70 {
        padding: 70px;
    }

    .sm-padding60 {
        padding: 60px;
    }

    .sm-padding50 {
        padding: 50px;
    }

    .sm-padding40 {
        padding: 40px;
    }

    .sm-padding30 {
        padding: 30px;
    }

    .sm-padding20 {
        padding: 20px;
    }

    .sm-padding10 {
        padding: 10px;
    }

    .sm-padding0 {
        padding: 0px;
    }

    /*-----------------*/
    .section-padding {
        padding: 80px 0;
    }

    .padding-top {
        padding-top: 80px;
    }

    .padding-bottom {
        padding-bottom: 80px;
    }

    .padding-100-50 {
        padding-top: 80px;
        padding-bottom: 30px;
    }

    .padding-100-70 {
        padding-top: 80px;
        padding-bottom: 50px;
    }

    .padding-100-30 {
        padding-top: 80px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .xs-padding-top-10 {
        padding-top: 10px;
    }

    .xs-padding-top-20 {
        padding-top: 20px;
    }

    .xs-padding-top-30 {
        padding-top: 30px;
    }

    .xs-padding-top-40 {
        padding-top: 40px;
    }

    .xs-padding-top-50 {
        padding-top: 50px;
    }

    .xs-padding-top-60 {
        padding-top: 60px;
    }

    .xs-padding-top-70 {
        padding-top: 70px;
    }

    .xs-padding-top-80 {
        padding-top: 80px;
    }

    .xs-padding-top-90 {
        padding-top: 90px;
    }

    .xs-padding-top-100 {
        padding-top: 100px;
    }

    .xs-padding-bottom-10 {
        padding-bottom: 10px;
    }

    .xs-padding-bottom-20 {
        padding-bottom: 20px;
    }

    .xs-padding-bottom-30 {
        padding-bottom: 30px;
    }

    .xs-padding-bottom-40 {
        padding-bottom: 40px;
    }

    .xs-padding-bottom-50 {
        padding-bottom: 50px;
    }

    .xs-padding-bottom-60 {
        padding-bottom: 60px;
    }

    .xs-padding-bottom-70 {
        padding-bottom: 70px;
    }

    .xs-padding-bottom-80 {
        padding-bottom: 80px;
    }

    .xs-padding-bottom-90 {
        padding-bottom: 90px;
    }

    .xs-padding-bottom-100 {
        padding-bottom: 100px;
    }

    /*------------------*/
    .xs-padding100 {
        padding: 100px;
    }

    .xs-padding90 {
        padding: 90px;
    }

    .xs-padding80 {
        padding: 80px;
    }

    .xs-padding70 {
        padding: 70px;
    }

    .xs-padding60 {
        padding: 60px;
    }

    .xs-padding50 {
        padding: 50px;
    }

    .xs-padding40 {
        padding: 40px;
    }

    .xs-padding30 {
        padding: 30px;
    }

    .xs-padding20 {
        padding: 20px;
    }

    .xs-padding10 {
        padding: 10px;
    }

    .xs-padding0 {
        padding: 0px;
    }

    /*----------------*/
    .section-padding {
        padding: 50px 0;
    }

    .padding-top {
        padding-top: 50px;
    }

    .padding-bottom {
        padding-bottom: 50px;
    }

    .padding-100-50 {
        padding-top: 50px;
        padding-bottom: 0;
    }

    .padding-100-70 {
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .padding-100-30 {
        padding-top: 50px;
        padding-bottom: 0px;
    }
}

/* ----------------------------
    BACKGROUND CSS
------------------------------- */

.theme__bg1{
    position: relative;
    z-index: 2;
}
.theme__bg1:after{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #233645;
    z-index: -1;
}


.theme__bg2{
    position: relative;
    z-index: 2;
}
.theme__bg2:after{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #EBF5F6;
    z-index: -1;
}

.white__bg{
    background: #fff;
}

.bgf{
    background-attachment: fixed;
}

/* ----------------------------
    BORDER CSS
------------------------------ */

.border {
    border: 1px solid #dfdfde;
}

.border-left {
    border-left: 1px solid #dddddd;
}

.border-right {
    border-right: 1px solid #dddddd;
}

.border-bottom {
    border-bottom: 1px solid #dddddd;
}

.border-top {
    border-top: 1px solid #dddddd;
}

.border-righ-bottom {
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
}

.border_black_1px{
    border: 1px solid #222; 
}
.border_black_2px{
    border: 2px solid #222; 
}.border_black_3px{
    border: 3px solid #222; 
}

@media only screen and (max-width: 767px) {
    .border-xs {
        border: 1px solid #dddddd;
    }
}



.border-radious3 {
    border-radius: 3px;
    overflow: hidden;
}

.border-radious4 {
    border-radius: 4px;
    overflow: hidden;
}
.border-radious5 {
    border-radius: 5px;
    overflow: hidden;
}

.border-radious55 {
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}

.border-radious7 {
    border-radius: 7px;
    overflow: hidden;
}




.border-radious10 {
    border-radius: 10px;
}

.border-radious20 {
    border-radius: 20px;
}

.border-radious50 {
    border-radius: 50px;
}

.border-_5px_white{
    border: .5px solid #fff;
}

/*-----------------------
    SHADOW CSS
------------------------*/
.shadow {
    -webkit-box-shadow: 0 0 3px #dddddd;
    box-shadow: 0 0 3px #dddddd;
}

.shadow10 {
    -webkit-box-shadow: 0 0 10px #dddddd;
    box-shadow: 0 0 3px #dddddd;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.shadow10:hover,
.shadow:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.no-shadow {
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
}

/* ----------------------------
    FORM & INPUT CSS
------------------------------ */

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
    outline: 0;
}

button:active,
button:focus,
input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:active,
input[type="reset"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
    outline: 0;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    outline: 0;
    width: 100%;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus,
select:focus {
    outline: 0;
}

select {
    border: 1px solid #ccc;
}

textarea {
    width: 100%;
}

embed,
iframe,
object {
    width: 100%;
}



/*==============
COMMON CSS
=================*/

.bg{
    background-position: center !important;
    -webkit-background-size: cover !important;
    background-size: cover !important;
}

/*==============
SPACE CSS
=================*/

.space-100 {
    height: 100px;
}

.space-50 {
    height: 50px;
}

.space-80 {
    height: 80px;
}

.space-40 {
    height: 40px;
}

.space-60 {
    height: 60px;
}
.space-70 {
    height: 70px;
}

.space-30 {
    height: 30px;
}

.space-20 {
    height: 20px;
}

.space-15 {
    height: 15px;
}

.space-10 {
    height: 10px;
}

.space-5{
    height: 5px;
}